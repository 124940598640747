<template>
  <div class="dishes">
    <v-card-title
      v-if="customHeadings && customHeadings.dishes.name"
      class="headline font-weight-black pl-0 pb-0"
    >
      {{ customHeadings.dishes.name }}
    </v-card-title>
    <span
      v-if="customHeadings && customHeadings.dishes.description"
      class="subtitle-1"
      >{{ customHeadings.dishes.description }}</span
    >

    <div v-for="category in data" v-bind:key="category._id">
      <div
        v-if="category.items.length !== 0"
        class="category"
        v-bind:id="category._id"
      >
        <v-card-title class="headline font-weight-black pl-0 pb-0">
          {{ category.name }}
        </v-card-title>
        <span v-if="category.description" class="subtitle-1">{{
          category.description
        }}</span>
        <v-expansion-panels
          accordion
          :class="$vuetify.breakpoint.lgAndUp ? 'justify-start' : null"
        >
          <v-expansion-panel
            v-for="(dish, i) in category.items"
            :key="i"
            active-class="active"
            :disabled="
              (!dish.allowedDeliveryMethods.takeaway &&
                !dish.allowedDeliveryMethods.delivery &&
                !dish.allowedDeliveryMethods.inplace) ||
              !isSelectedDeliveryMethodAllowed(dish)
            "
            :style="
              $vuetify.breakpoint.lgAndUp
                ? 'max-width: 50%; border-color: transparent'
                : null
            "
          >
            <v-expansion-panel-header hide-actions>
              <v-col
                cols="1"
                sm="3"
                lg="3"
                class="hidden-xs-only image-thumbnail"
              >
                <v-img
                  v-if="dish.imageUrl"
                  class="mr-auto"
                  style="border-radius: 8px"
                  height="125"
                  :contain="imageContain === 'true'"
                  :src="dish.imageUrl"
                ></v-img>
                <v-img
                  v-else
                  class="mr-auto"
                  style="border-radius: 8px"
                  height="125"
                  width="100%"
                  src="https://embeddable.piknik.cc/img/demo/placeholder.gif"
                ></v-img>
              </v-col>
              <v-col cols="12" sm="9">
                <v-card-title style="padding-left: 0px; padding-top: 0px"
                  ><span
                    v-if="order.findIndex((item) => item._id === dish._id) > -1"
                    class="primary--text"
                    style="margin-right: 8px"
                    >{{
                      order
                        .filter((item) => item._id === dish._id)
                        .reduce(
                          (accumulator, dish) => accumulator + dish.quantity,
                          0
                        )
                    }}x</span
                  >{{ dish.name }}</v-card-title
                >
                <v-card-subtitle class="pl-0 pb-2"
                  ><span class="full-description"
                    >{{ dish.description }}
                    <v-card-text
                      style="padding: 0px"
                      v-if="dish.allergens.length > 0"
                    >
                      {{ $t("allergens") }}: {{ dish.allergens.join(", ") }}
                    </v-card-text>
                  </span>
                  <v-list-item-subtitle class="short-description"
                    >{{ dish.description }}
                  </v-list-item-subtitle>
                </v-card-subtitle>
                <v-card-text style="padding-left: 0px; padding-bottom: 0px">
                  <span class="font-weight-bold" style="padding-bottom: 0px"
                    >{{ calculateDishPrice(dish).toFixed(2)
                    }}{{ currencySymbol }}</span
                  >&#160;/{{ dish.amount }}
                  <v-btn
                    v-if="mandatoryOptionsProvided(dish)"
                    text
                    rounded
                    color="primary"
                    small
                    :disabled="
                      (!isOpen && !allowPreorderingWhenClosed) ||
                      (!dish.allowedDeliveryMethods.takeaway &&
                        !dish.allowedDeliveryMethods.delivery &&
                        !dish.allowedDeliveryMethods.inplace) ||
                      !mandatoryOptionsProvided(dish) ||
                      !isSelectedDeliveryMethodAllowed(dish)
                    "
                    @click.native.stop="
                      $emit('add-dish', {
                        dish,
                        options: selectedOptions[dish._id].filter(
                          (e) => dish.options.indexOf(e._id) !== -1
                        ),
                        price: calculateDishPrice(dish),
                      });
                      resetOptions(dish);
                    "
                  >
                    {{ $t("add") }}
                  </v-btn>
                  <v-btn
                    v-else
                    text
                    rounded
                    color="primary"
                    small
                    :disabled="
                      (!isOpen && !allowPreorderingWhenClosed) ||
                      (!dish.allowedDeliveryMethods.takeaway &&
                        !dish.allowedDeliveryMethods.delivery &&
                        !dish.allowedDeliveryMethods.inplace) ||
                      !isSelectedDeliveryMethodAllowed(dish)
                    "
                  >
                    {{ $t("selectOptions") }}
                  </v-btn>

                  <div>
                    <AllowedDeliveryMethods
                      :allowedDeliveryMethods="dish.allowedDeliveryMethods"
                    />
                  </div>
                </v-card-text>
              </v-col>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-col cols="12" style="padding-top: 0px">
                <v-img
                  v-if="dish.imageUrl && dish.options.length !== 0"
                  class="d-sm-none mb-4"
                  style="border-radius: 8px"
                  max-height="250"
                  :contain="imageContain === 'true'"
                  :src="dish.imageUrl"
                ></v-img>
                <v-img
                  v-else-if="dish.imageUrl"
                  class="d-sm-none"
                  style="border-radius: 8px"
                  max-height="250"
                  :contain="imageContain === 'true'"
                  :src="dish.imageUrl"
                ></v-img>
                <v-card-text class="hidden-xs-only" style="padding: 0px">
                  {{ dish.description }}
                </v-card-text>
                <v-card-text
                  class="hidden-xs-only"
                  style="padding: 0px"
                  v-if="dish.allergens.length > 0"
                >
                  {{ $t("allergens") }}: {{ dish.allergens.join(", ") }}
                </v-card-text>
                <Options
                  v-if="dish.options.length !== 0"
                  :currencySymbol="currencySymbol"
                  :dish="dish"
                  :options="options"
                  :selectedOptions="selectedOptions[dish._id]"
                  :expandFirstOption="expandFirstOption"
                  class="options"
                  v-on:add-option="addOption($event, dish)"
                  v-on:remove-option="removeOption($event, dish)"
                />
              </v-col>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
  </div>
</template>

<script>
import Options from "./Options";
import AllowedDeliveryMethods from "./AllowedDeliveryMethods";

export default {
  name: "Dishes",
  props: [
    "isOpen",
    "allowPreorderingWhenClosed",
    "currencySymbol",
    "data",
    "options",
    "order",
    "customHeadings",
    "expandFirstOption",
    "imageContain",
  ],
  components: {
    Options,
    AllowedDeliveryMethods,
  },
  data: () => ({
    selectedOptions: [],
  }),
  created: function () {
    this.data.forEach((date) => {
      date.items.forEach((dish) => {
        this.$set(this.selectedOptions, dish._id, []);
      });
    });
  },
  methods: {
    addOption: function (event, dish) {
      const option = event.option;
      const value = event.value;
      const index = this.selectedOptions[dish._id].findIndex(
        (e) => e._id === option._id
      );
      if (index === -1) {
        this.selectedOptions[dish._id].push({
          _id: option._id,
          values: [
            {
              _id: value._id,
              name: value.name,
              price: value.price,
              quantity: 1,
            },
          ],
        });
      } else {
        const index2 = this.selectedOptions[dish._id][index].values.findIndex(
          (e) => e._id === value._id
        );
        if (index2 === -1) {
          this.selectedOptions[dish._id][index].values.push({
            _id: value._id,
            name: value.name,
            price: value.price,
            quantity: 1,
          });
        } else {
          this.selectedOptions[dish._id][index].values[index2].quantity++;
        }
      }
    },
    removeOption: function (event, dish) {
      const option = event.option;
      const value = event.value;
      const index = this.selectedOptions[dish._id].findIndex(
        (e) => e._id === option._id
      );
      if (index !== -1) {
        const index2 = this.selectedOptions[dish._id][index].values.findIndex(
          (e) => e._id === value._id
        );
        if (index2 !== -1) {
          const quantity =
            this.selectedOptions[dish._id][index].values[index2].quantity;
          if (quantity === 1)
            this.selectedOptions[dish._id][index].values.splice(index2, 1);
          else this.selectedOptions[dish._id][index].values[index2].quantity--;
        }
      }
    },
    mandatoryOptionsProvided: function (dish) {
      try {
        const options = this.options
          .filter((e) => dish.options.indexOf(e._id) !== -1)
          .filter((e) => e.minimumSelections >= 1);

        for (let i = 0; i < options.length; i++) {
          const index = this.selectedOptions[dish._id].findIndex(
            (e) => e._id === options[i]._id
          );

          if (
            !(
              this.selectedOptions[dish._id][index].values.length >=
              options[i].minimumSelections
            )
          )
            return false;
        }
        return true;
      } catch {
        return false;
      }
    },
    calculateDishPrice: function (dish) {
      let price = dish.price;
      const options = this.selectedOptions[dish._id].filter(
        (e) => dish.options.indexOf(e._id) !== -1
      );

      options.forEach((option) => {
        let optionsTotal = 0;
        option.values.forEach((value) => {
          optionsTotal += value.quantity * value.price;
        });
        price += optionsTotal;
      });

      return price;
    },
    resetOptions: function (dish) {
      this.$set(this.selectedOptions, dish._id, []);
    },
    isSelectedDeliveryMethodAllowed: function (dish) {
      const params = new URLSearchParams(window.location.search);
      const deliveryMethod = params.get("deliveryMethod");

      if (
        deliveryMethod === "takeaway" ||
        deliveryMethod === "delivery" ||
        deliveryMethod === "inplace"
      )
        return dish.allowedDeliveryMethods[deliveryMethod];

      return true;
    },
  },
};
</script>

<style>
.v-card__title {
  word-break: unset !important;
}

[piknik] .image-thumbnail {
  padding-right: 12px !important;
}

.v-expansion-panel:before {
  box-shadow: none !important;
}

[piknik] .full-description {
  display: none;
}

[piknik] .options {
  margin-top: 16px;
}

@media (max-width: 600px) {
  .v-expansion-panel-header {
    padding: 0 !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .v-expansion-panel-content__wrap {
    padding: 0 !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  [piknik] .active > * .full-description {
    display: block !important;
  }

  [piknik] .active > * .short-description {
    display: none !important;
  }

  [piknik] .options {
    margin-top: 0px;
  }
}

@media (min-width: 600px) {
  .v-expansion-panel-header {
    padding: 16px 16px !important;
  }

  .v-expansion-panel-content__wrap {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}

.dailyMenu .v-card__subtitle {
  padding-bottom: 8px !important;
}

@media (min-width: 1264px) {
  [piknik] .dishes .v-expansion-panel:not(:first-child)::after {
    border-top: none;
  }

  [piknik] .dishes .options .v-expansion-panel:not(:first-child)::after {
    border-top: thin solid;
    border-color: rgba(0, 0, 0, 0.12);
  }

  [piknik] .dishes .v-expansion-panel:nth-child(1) .image-thumbnail,
  [piknik] .dishes .v-expansion-panel:nth-child(2) .image-thumbnail {
    padding-top: 0;
  }
}
</style>