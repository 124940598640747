<template>
  <v-card min-height="100px" class="px-2">
    <v-card-title v-if="step === 0" class="headline font-weight-black py-4">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            x-small
            :color="isOpenColor"
            style="margin-right: 8px"
            v-bind="attrs"
            v-on="on"
          >
            mdi-checkbox-blank-circle
          </v-icon>
        </template>
        <span v-if="isOpen === null">{{ $t("noData") }}</span>
        <span v-else>{{
          isOpen
            ? $t("isOpenTrue")
            : allowPreorderingWhenClosed
            ? $t("isOpenFalseButAcceptingPreorders")
            : $t("isOpenFalse")
        }}</span>
      </v-tooltip>
      {{ isPreorder ? $t("preOrder") : $t("order") }}
      <span class="ml-auto">
        <v-btn
          text
          rounded
          color="primary"
          :disabled="
            (!isOpen && !allowPreorderingWhenClosed) ||
            order.length === 0 ||
            minimalOrderValue > orderTotal ||
            (!isTakewayAllowed && !isDeliveryAllowed && !isInplaceAllowed)
          "
          @click="step++"
        >
          <span v-if="minimalOrderValue > orderTotal">
            {{ $vuetify.breakpoint.xs ? "Min" : $t("minimum") }}
            {{ `${minimalOrderValue.toFixed(2)} ${currencySymbol}` }}
          </span>
          <span v-else>
            <span v-if="$vuetify.breakpoint.xs">
              <v-icon>mdi-arrow-right</v-icon>
            </span>
            <span v-else>
              {{ $t("continue") }}
            </span>
          </span>

          <!-- {{
              minimalOrderValue > orderTotal
                ? `${$t("minimum")} ${minimalOrderValue.toFixed(
                    2
                  )} ${currencySymbol}`
                : $t("continue")
            }} -->
        </v-btn>
      </span>
    </v-card-title>
    <v-card-title v-if="step === 1" class="headline font-weight-black py-4">
      <v-btn
        icon
        color="primary"
        small
        @click="step--"
        style="margin-right: 8px"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      {{ $t("contact") }}
      <span class="ml-auto">
        <v-btn
          text
          rounded
          color="primary"
          :disabled="
            !valid ||
            (!isTakewayAllowed && !isDeliveryAllowed && !isInplaceAllowed) ||
            (deliveryMethod === 'delivery' &&
              !onlinePayment &&
              deliveryDetails.cashOnDeliveryType === undefined) ||
            minimalOrderValue > orderTotal
          "
          @click="step++"
        >
          {{
            minimalOrderValue > orderTotal
              ? `${$t("minimum")} ${minimalOrderValue.toFixed(
                  2
                )} ${currencySymbol}`
              : $t("check")
          }}
        </v-btn>
      </span>
    </v-card-title>
    <v-card-title v-if="step === 2" class="headline font-weight-black py-4">
      <v-btn
        icon
        color="primary"
        small
        @click="step--"
        style="margin-right: 8px"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      {{ $t("summary") }}
      <span class="ml-auto">
        <v-btn
          text
          rounded
          color="#fc010e"
          :disabled="
            !agreementCheckbox ||
            (!isTakewayAllowed && !isDeliveryAllowed && !isInplaceAllowed) ||
            !isCurrentDeliveryPossible
          "
          :loading="loading"
          @click="sendOrder"
        >
          {{ onlinePayment ? $t("toPay") : $t("toOrder") }}
        </v-btn>
      </span>
    </v-card-title>
    <v-card-title v-if="step === 3" class="headline font-weight-black py-4">
      {{ responseTitle }}
      <v-btn
        icon
        color="primary"
        small
        @click="resetShoppingCart"
        class="ml-auto"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text v-if="step === 1" class="pb-4">
      <v-chip-group
        v-if="
          deliveryMethodSelecting &&
          (isTakewayAllowed || isDeliveryAllowed || isInplaceAllowed)
        "
        v-model="deliveryMethod"
        @change="checkMinimalOrderValue(customer.address.deliveryZone)"
        mandatory
        center-active
        active-class="primary--text"
      >
        <v-chip
          v-if="deliveryOptions.takeaway.status && isTakewayAllowed"
          filter
          value="takeaway"
          >{{ $t("takeaway") }}</v-chip
        >
        <v-chip
          v-if="deliveryOptions.delivery.status && isDeliveryAllowed"
          filter
          value="delivery"
          >{{ $t("delivery") }}</v-chip
        >
        <v-chip
          v-if="deliveryOptions.inplace.status && isInplaceAllowed"
          filter
          value="inplace"
          >{{ $t("inplace") }}</v-chip
        >
      </v-chip-group>
      <v-alert
        v-if="!isTakewayAllowed && !isDeliveryAllowed && !isInplaceAllowed"
        type="error"
        text
        dense
        border="left"
        class="mb-2"
        >{{ $t("notPossibleToOrderItemsTogether") }}</v-alert
      >
      <v-form
        ref="form"
        v-model="valid"
        :disabled="!isTakewayAllowed && !isDeliveryAllowed && !isInplaceAllowed"
      >
        <v-text-field
          dense
          v-model="customer.name"
          :label="$t('customerName')"
          rounded
          filled
          hide-details
          :rules="[rules.required]"
          style="margin-bottom: 8px"
        ></v-text-field>
        <v-text-field
          dense
          v-model="customer.email"
          @change="$emit('change-customer', customer)"
          :label="$t('customerEmail')"
          rounded
          filled
          hide-details
          :rules="[rules.required, rules.email]"
          style="margin-bottom: 8px"
        ></v-text-field>
        <v-text-field
          dense
          v-model="customer.mobile"
          @change="$emit('change-customer', customer)"
          :label="$t('customerMobile')"
          placeholder="+421 xxx xxx xxx"
          rounded
          filled
          hide-details
          :rules="[rules.required]"
          style="margin-bottom: 8px"
        ></v-text-field>
        <v-text-field
          v-if="deliveryMethod === 'delivery'"
          dense
          v-model="customer.address.street"
          :label="$t('customerAddressStreet')"
          rounded
          filled
          hide-details
          :rules="[rules.required]"
          style="margin-bottom: 8px"
        ></v-text-field>
        <v-text-field
          v-if="deliveryMethod === 'delivery'"
          dense
          v-model="customer.address.city"
          :label="$t('customerAddressCity')"
          rounded
          filled
          hide-details
          :rules="[rules.required]"
          style="margin-bottom: 8px"
        ></v-text-field>
        <v-select
          v-if="deliveryMethod === 'delivery'"
          :items="deliveryOptions.delivery.zones"
          v-model="customer.address.deliveryZone"
          @change="checkMinimalOrderValue(customer.address.deliveryZone)"
          item-text="name"
          item-value="_id"
          dense
          :label="$t('customerAddressDeliveryZone')"
          rounded
          filled
          hide-details
          :rules="[rules.required]"
          style="margin-bottom: 8px"
        ></v-select>
        <!-- <v-text-field
          v-if="deliveryMethod === 'inplace' && !deliveryDetails.tableId"
          dense
          v-model="deliveryDetails.time"
          :label="$t('time')"
          placeholder="12:00"
          rounded
          filled
          hide-details
          :rules="[rules.required, rules.digitalTime]"
          style="margin-bottom: 8px"
        ></v-text-field> -->
        <v-text-field
          v-if="deliveryMethod === 'inplace' && !deliveryDetails.tableId"
          dense
          v-model="deliveryDetails.persons"
          type="number"
          :label="$t('persons')"
          rounded
          filled
          hide-details
          :rules="[rules.required, rules.integer, rules.persons]"
          style="margin-bottom: 8px"
        ></v-text-field>
        <v-textarea
          :label="$t('note')"
          v-model="note"
          rows="2"
          rounded
          filled
          hide-details
          style="margin-bottom: 16px"
        ></v-textarea>
        <v-chip-group
          v-model="onlinePayment"
          mandatory
          center-active
          class="paymentOptions"
          active-class="primary--text"
        >
          <span class="subheading mr-2" style="line-height: 32px !important"
            >{{ $t("payment") }}:</span
          >
          <v-chip
            v-if="paymentOptions.cashOnDelivery.status"
            :disabled="
              !isTakewayAllowed && !isDeliveryAllowed && !isInplaceAllowed
            "
            filter
            :value="false"
            >{{ $t("cashOnDelivery") }}</v-chip
          >
          <v-chip
            v-if="paymentOptions.onlinePayments.status"
            :disabled="
              !isTakewayAllowed && !isDeliveryAllowed && !isInplaceAllowed
            "
            filter
            :value="true"
            >{{ $t("onlinePayments") }}</v-chip
          >
        </v-chip-group>
        <v-chip-group
          v-if="deliveryMethod === 'delivery' && !onlinePayment"
          v-model="deliveryDetails.cashOnDeliveryType"
          center-active
          :mandatory="
            !paymentOptions.cashOnDelivery.allowedTypes.cash ||
            !paymentOptions.cashOnDelivery.allowedTypes.card
          "
          class="paymentOptions mt-2"
          active-class="primary--text"
        >
          <span
            class="subheading mr-2"
            :class="
              deliveryMethod === 'delivery' &&
              !onlinePayment &&
              deliveryDetails.cashOnDeliveryType === undefined
                ? 'error--text'
                : ''
            "
            style="line-height: 32px !important"
          >
            <v-icon
              v-if="
                deliveryMethod === 'delivery' &&
                !onlinePayment &&
                deliveryDetails.cashOnDeliveryType === undefined
              "
              color="error"
              >mdi-alert-circle</v-icon
            >
            {{ $t("howWillYouPay") }}</span
          >
          <v-chip
            v-if="paymentOptions.cashOnDelivery.allowedTypes.cash"
            filter
            value="cash"
            >{{ $t("cash") }}</v-chip
          >
          <v-chip
            v-if="paymentOptions.cashOnDelivery.allowedTypes.card"
            filter
            value="card"
            >{{ $t("card") }}</v-chip
          >
        </v-chip-group>
        <v-checkbox
          v-model="saveCustomerCheckbox"
          :label="$t('saveCustomerData')"
          color="primary"
          hide-details
        ></v-checkbox>
      </v-form>
    </v-card-text>
    <v-card-text v-if="step === 0" style="padding-bottom: 0px">
      <v-alert
        v-if="!isTakewayAllowed && !isDeliveryAllowed && !isInplaceAllowed"
        type="error"
        text
        dense
        border="left"
        class="mb-2"
        >{{ $t("notPossibleToOrderItemsTogether") }}</v-alert
      >
      <v-expansion-panels accordion readonly>
        <v-expansion-panel
          v-for="(dish, i) in order"
          :key="i"
          active-class="active"
        >
          <v-expansion-panel-header hide-actions>
            <v-col
              cols="1"
              sm="3"
              lg="3"
              class="hidden-xs-only image-thumbnail"
            >
              <v-img
                v-if="dish.imageUrl"
                class="mr-auto"
                style="border-radius: 8px"
                height="75"
                width="100%"
                :src="dish.imageUrl"
              ></v-img>
              <v-img
                v-else
                class="mr-auto"
                style="border-radius: 8px"
                height="75"
                width="100%"
                src="https://embeddable.piknik.cc/img/demo/placeholder.gif"
              ></v-img>
            </v-col>
            <v-col cols="9" sm="7">
              <div></div>
              <v-card-title style="padding-left: 0px; padding-top: 0px">
                <v-list-item-title style="font-size: 20px"
                  ><span style="margin-right: 8px" class="primary--text"
                    >{{ dish.quantity }}x</span
                  >{{ dish.name }}</v-list-item-title
                >
              </v-card-title>
              <v-card-subtitle style="padding-left: 0px; padding-bottom: 8px">
                <span
                  v-if="optionsToString(dish) !== dish.description"
                  class="short-description"
                  ><span style="margin-right: 4px" class="primary--text"
                    >+ {{ dish.quantity }}x</span
                  >({{ optionsToString(dish) }})</span
                >
                <v-list-item-subtitle v-else class="short-description">{{
                  dish.description
                }}</v-list-item-subtitle>
              </v-card-subtitle>
              <v-card-text style="padding-left: 0px; padding-bottom: 0px">
                <span class="font-weight-bold" style="padding-bottom: 0px"
                  >{{ (dish.price * dish.quantity).toFixed(2)
                  }}{{ currencySymbol }}</span
                >&#160;({{ dish.quantity }} x {{ dish.price.toFixed(2)
                }}{{ currencySymbol }})

                <AllowedDeliveryMethods
                  class="ml-2"
                  :allowedDeliveryMethods="dish.allowedDeliveryMethods"
                />
              </v-card-text>
            </v-col>
            <v-col cols="3" sm="2" style="text-align: end">
              <v-btn
                icon
                color="#0360ec"
                small
                @click.native.stop="$emit('add-dish', { dish })"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-btn
                icon
                color="#fc010e"
                small
                @click.native.stop="$emit('remove-dish', dish)"
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn>
            </v-col>
          </v-expansion-panel-header>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
    <v-card-text v-if="step === 2" style="padding-bottom: 0px">
      <v-alert
        v-if="!isTakewayAllowed && !isDeliveryAllowed && !isInplaceAllowed"
        type="error"
        text
        dense
        border="left"
        class="mb-2"
        >{{ $t("notPossibleToOrderItemsTogether") }}</v-alert
      >
      <v-alert
        v-else-if="!isCurrentDeliveryPossible"
        type="error"
        text
        dense
        border="left"
        class="mb-2"
        >{{ $t("changeDeliveryMethod") }}</v-alert
      >
      <OrderSummary
        ref="orderSummary"
        :isPreorder="isPreorder"
        :orderScheduling="orderScheduling"
        :scheduledAt="scheduledAt"
        :currencySymbol="currencySymbol"
        :order="order"
        :deliveryMethod="deliveryMethod"
        :deliveryDetails="deliveryDetails"
        :deliveryOptions="deliveryOptions"
        :onlinePayment="onlinePayment"
        :customer="customer"
        :note="note"
        :loyaltyProgram="loyaltyProgram"
        :earnedLoyaltyCredits="earnedLoyaltyCredits"
        :stripe="stripe"
        :agreementsUrl="agreementsUrl"
        :gdprUrl="gdprUrl"
        v-on:agreement-change="agreementCheckbox = $event"
        v-on:clear-order="$emit('clear-order')"
      />
    </v-card-text>
    <v-card-text v-if="step === 3" class="pb-4">
      <p style="color: rgba(0, 0, 0, 0.87)">
        {{ responseMessage }}
      </p>
    </v-card-text>
    <v-card-title
      v-if="order.length !== 0 && (step === 0 || step === 2)"
      class="font-weight-black py-4"
    >
      <v-tooltip top v-if="orderTotal !== orderTotalAfterDiscount">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" color="primary" class="mr-2"
            >mdi-diamond-stone</v-icon
          >
        </template>
        <span>{{
          $t("discountedPriceAfterLoyaltyCredits", {
            loyaltyCredits: customersLoyaltyCredits,
          })
        }}</span>
      </v-tooltip>
      {{ $t("total") }}:<span
        style="margin-left: 8px; margin-right: 8px"
        class="primary--text"
        >{{ orderTotalWithDelivery.toFixed(2) }}{{ currencySymbol }}</span
      >
      <v-tooltip v-if="deliveryMethod === 'delivery'" top>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on"
            >({{ deliveryPrice.toFixed(2) }}{{ currencySymbol }}
            {{ $t("delivery").toLowerCase() }})</span
          >
        </template>
        <span
          v-if="
            deliveryOptions.delivery.zones.filter(
              (zone) => zone._id === this.customer.address.deliveryZone
            )[0]
          "
        >
          {{
            $t("deliveryDetail", {
              zone: deliveryOptions.delivery.zones.filter(
                (zone) => zone._id === this.customer.address.deliveryZone
              )[0].name,
            })
          }}</span
        >
      </v-tooltip>
    </v-card-title>
  </v-card>
</template>

<script>
import OrderSummary from "./OrderSummary";
import AllowedDeliveryMethods from "./AllowedDeliveryMethods";

export default {
  name: "ShoppingCart",
  props: [
    "restaurantId",
    "isOpen",
    "scheduledAt",
    "orderScheduling",
    "allowPreorderingWhenClosed",
    "isPreorder",
    "currencySymbol",
    "minimalOrderValue",
    "defaultMinimalOrderValue",
    "deliveryOptions",
    "paymentOptions",
    "status",
    "order",
    "orderTotal",
    "orderTotalAfterDiscount",
    "loyaltyProgram",
    "customersLoyaltyCredits",
    "stripe",
    "agreementsUrl",
    "gdprUrl",
  ],
  components: {
    OrderSummary,
    AllowedDeliveryMethods,
  },
  data: () => ({
    deliveryMethodSelecting: true,
    deliveryMethod: "takeaway",
    deliveryDetails: {
      cashOnDeliveryType: undefined,
      time: null,
      persons: null,
      tableId: null,
    },
    onlinePayment: false,
    loading: false,
    customer: {
      name: null,
      email: null,
      mobile: null,
      address: {
        street: null,
        city: null,
        deliveryZone: null,
      },
    },
    note: "",
    step: 0,
    valid: false,
    rules: {
      required: (value) => !!value || "Field is required",
      email: (value) => /.+@.+\..+/.test(value) || "E-mail must be valid",
      digitalTime: (value) =>
        /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value) || "HH:MM",
      integer: (value) =>
        Number.isInteger(Number(value)) || "Value must be an integer",
      persons: (value) => (value > 0 && value <= 99) || "Max 99",
    },
    // emailRules: [
    //   (v) => !!v || "E-mail is required",
    //   (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    // ],
    // required: [(v) => !!v || "Field is required"],
    saveCustomerCheckbox: true,
    agreementCheckbox: false,
    responseTitle: "",
    responseMessage: "",
  }),
  computed: {
    isOpenColor: function () {
      if (this.isOpen === true) return "#13e46d";
      else if (this.allowPreorderingWhenClosed) return "#ff9f1a";
      else if (this.isOpen === false) return "#fc010e";
      else return "gray";
    },
    deliveryPrice: function () {
      try {
        const deliveryZone = this.deliveryOptions.delivery.zones.filter(
          (zone) => zone._id === this.customer.address.deliveryZone
        )[0];

        let deliveryPrice = deliveryZone.price;
        if (
          deliveryZone.discountThreshold !== null &&
          deliveryZone.discountedPrice !== null
        ) {
          if (this.orderTotal >= deliveryZone.discountThreshold)
            deliveryPrice = deliveryZone.discountedPrice;
        }
        return deliveryPrice;
      } catch {
        return 0;
      }
    },
    orderTotalWithDelivery: function () {
      if (this.deliveryMethod === "delivery")
        return (
          Math.round(
            (this.orderTotalAfterDiscount + this.deliveryPrice) * 1e2
          ) / 1e2
        );
      return this.orderTotalAfterDiscount;
    },
    isTakewayAllowed: function () {
      for (let index = 0; index < this.order.length; index++) {
        if (!this.order[index].allowedDeliveryMethods.takeaway) return false;
      }
      return true;
    },
    isDeliveryAllowed: function () {
      for (let index = 0; index < this.order.length; index++) {
        if (!this.order[index].allowedDeliveryMethods.delivery) return false;
      }
      return true;
    },
    isInplaceAllowed: function () {
      for (let index = 0; index < this.order.length; index++) {
        if (!this.order[index].allowedDeliveryMethods.inplace) return false;
      }
      return true;
    },
    isCurrentDeliveryPossible: function () {
      if (this.deliveryMethod === "takeaway") return this.isTakewayAllowed;
      if (this.deliveryMethod === "delivery") return this.isDeliveryAllowed;
      if (this.deliveryMethod === "inplace") return this.isInplaceAllowed;
      return false;
    },
    earnedLoyaltyCredits: function () {
      let earnedLoyaltyCredits = 0;
      if (this.loyaltyProgram && this.loyaltyProgram.status) {
        earnedLoyaltyCredits = Math.round(
          (Math.round(
            this.orderTotalWithDelivery *
              (this.loyaltyProgram.loyaltyCreditsPercentage / 100) *
              1e2
          ) /
            1e2) *
            100
        );
        if (
          earnedLoyaltyCredits >
          this.loyaltyProgram.maximumRedeemedLoyaltyCredits
        )
          earnedLoyaltyCredits =
            this.loyaltyProgram.maximumRedeemedLoyaltyCredits;
      }
      return earnedLoyaltyCredits;
    },
  },
  created: function () {
    const params = new URLSearchParams(window.location.search);

    const deliveryMethod = params.get("deliveryMethod");
    const tableId = params.get("tableId");

    if (
      deliveryMethod === "takeaway" ||
      deliveryMethod === "delivery" ||
      deliveryMethod === "inplace"
    ) {
      this.deliveryMethod = deliveryMethod;
      this.deliveryMethodSelecting = false;
    }

    if (tableId) this.deliveryDetails.tableId = tableId;

    if (params.get("status") === "success") {
      this.responseTitle = this.$t("thankYou");
      this.responseMessage = this.$t("thankYouForYourOrder", {
        orderId: params.get("orderId"),
      });
      this.step = 3;
    } else if (params.get("status") === "error") {
      this.responseTitle = this.$t("sorry");
      this.responseMessage = this.$t("paymentFailed");
      this.step = 3;
    }
  },
  mounted: function () {
    const customer = localStorage.getItem("customer");
    if (customer) {
      this.customer = JSON.parse(customer);
      if (
        this.customer.address &&
        this.customer.address.deliveryZone &&
        this.deliveryOptions.delivery.zones.some(
          (e) => `${e._id}` === `${this.customer.address.deliveryZone}`
        )
      )
        this.checkMinimalOrderValue(this.customer.address.deliveryZone);
      else this.customer.address.deliveryZone = null;
    }
  },
  methods: {
    checkMinimalOrderValue: function (zoneId) {
      const deliveryZone = this.deliveryOptions.delivery.zones.filter(
        (zone) => zone._id === zoneId
      )[0];

      let minimalOrderValue = this.defaultMinimalOrderValue;

      if (
        this.deliveryMethod === "delivery" &&
        deliveryZone &&
        deliveryZone.minimalOrderValue !== null
      )
        minimalOrderValue = deliveryZone.minimalOrderValue;

      this.$emit("change-minimal-order-value", minimalOrderValue);
    },
    optionsToString: function (dish) {
      const options = dish.options;
      let string = "";
      options.forEach((option) => {
        option.values.forEach((value) => {
          if (value.quantity > 1)
            string += `${value.quantity}x ${value.name}, `;
          else string += `${value.name}, `;
        });
      });
      if (string.length === 0) return dish.description;
      return string.slice(0, -2);
    },
    sendOrder: async function () {
      this.loading = true;
      if (this.saveCustomerCheckbox)
        localStorage.setItem("customer", JSON.stringify(this.customer));
      const data = await this.$refs.orderSummary.sendOrder(this.restaurantId);
      if (data && data.id) {
        this.responseTitle = this.$t("thankYou");
        this.responseMessage = this.$t("thankYouForYourOrder", {
          orderId: data.id,
        });
        this.step++;
      } else if (data && data.sessionId) {
        this.responseTitle = this.$t("payment");
        this.responseMessage = this.$t("redirecting");
        this.step++;
      } else {
        this.responseTitle = this.$t("sorry");
        this.responseMessage = this.$t("orderFailed");
        this.step++;
      }
    },
    resetShoppingCart: function () {
      this.$emit("close-shopping-cart");
      this.step = 0;
      this.loading = false;
    },
  },
};
</script>

<style>
@media (max-width: 600px) {
  .v-bottom-sheet > * .v-expansion-panel-header {
    padding: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .v-dialog > * .v-expansion-panel-header {
    padding: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.v-bottom-sheet .v-card,
.v-dialog .v-card {
  box-shadow: none !important;
  border-radius: 16px 16px 0 0 !important;
}

@media (min-width: 960px) {
  .v-bottom-sheet .v-card,
  .v-dialog .v-card {
    border-radius: 16px !important;
  }
}

@media (min-width: 600px) {
  .v-bottom-sheet > * .v-expansion-panel-header {
    padding: 0 0 !important;
  }

  .v-dialog > * .v-expansion-panel-header {
    padding: 0 0 !important;
  }
}

[piknik] .shopping-cart {
  z-index: 1000;
}

.v-dialog {
  box-shadow: none !important;
}

.v-expansion-panel:first-child .col-sm-7 {
  padding-top: 0 !important;
}

.v-expansion-panel:first-child .image-thumbnail {
  padding-top: 0 !important;
}

.v-expansion-panel:last-child .col-sm-7 {
  padding-bottom: 0 !important;
}

.v-expansion-panel:last-child .image-thumbnail {
  padding-bottom: 0 !important;
}

.v-chip-group .v-slide-group__content {
  padding: 0 !important;
  margin-bottom: 8px;
}

.v-chip-group .v-chip {
  margin: 0 8px 0 0 !important;
}

.chip-group-active-class {
  color: #0360ec !important;
  caret-color: #0360ec !important;
}

.paymentOptions .v-slide-group__content {
  margin-bottom: 0 !important;
}
</style>